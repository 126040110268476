import api from './api';

class UserCustomerService {
    findUserCustomerList(name, page, size) {
        return api.get(`/api/v1/userCustomer/findUserCustomerList?name=${name}&page=${page}&size=${size}`);
    }

    findUserCustomer(id) {
        return api.get(`/api/v1/userCustomer/findUserCustomer?id=${id}`);
    }

    saveUserCustomerNew(userCustomer) {
        return api.post('/api/v1/userCustomer/saveUserCustomerNew', JSON.stringify(userCustomer));
    }

    saveUserCustomerUpdate(userCustomer) {
        return api.post(`/api/v1/userCustomer/saveUserCustomerUpdate`, JSON.stringify(userCustomer));
    }
}
export default new UserCustomerService();
